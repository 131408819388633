import { Component } from '@angular/core';

@Component({
  selector: 'app-termini',
  standalone: true,
  imports: [],
  templateUrl: './termini.component.html',
  styleUrl: './termini.component.scss'
})
export class TerminiComponent {

}
