<footer class="text-gray-300 body-font bg-customDark overflow-hidden">
  <div class="mx-auto flex flex-col md:flex-row md:space-x-4 px-5 py-8 bg-customDark items-center">
    <div class="flex pb-2 title-font font-medium items-center md:justify-start justify-center">
      <picture>
        <source srcset="{{ ttaLogoUrlWebp }}" type="image/webp">
        <img alt="Top-Trading Academy Logo"
             class="filter invert transition duration-300 ease-in-out hover:scale-105" height="29"
             id="Top-Trading Academy Logo"
             src="{{ ttaLogoUrlPng }}" width="150">
      </picture>
    </div>
    <p class="w-full sm:w-2/4 md:w-2/3 text-xs text-center mx-auto mt-2 lg:mt-0">
      Top-Trading Academy è un logo registrato tutti i diritti riservati sono di TTGames s.r.l. con sede in: Via Edmondo
      Borri 8 - 28922 Verbania (VB) Italia - P.iva 01978000030
    </p>
    <span class="inline-flex text-xs sm:text-sm md:ml-auto sm:mt-0 mt-4 justify-center md:justify-start">
      <a [routerLink]="['/help']" class="ml-3 hover:text-redCustom">
        Aiuto
      </a>
      <a [routerLink]="['/disclaimer']" class="ml-3 hover:text-redCustom">
        Disclaimer
      </a>
      <a [routerLink]="['/termini-condizioni']" class="ml-3 hover:text-redCustom">
        Termini
      </a>
      <a class="ml-3 hover:text-redCustom" href="https://www.iubenda.com/privacy-policy/534659" rel="noreferrer"
         target="_blank">
        Privacy
      </a>
      <a class="ml-3 hover:text-redCustom" href="https://www.iubenda.com/privacy-policy/534659/cookie-policy"
         rel="noreferrer"
         target="_blank">
        Cookie
      </a>
    </span>
  </div>
</footer>
